import { Box } from '@sevenrooms/react-components/components/Box'
import { CircularProgress } from '@sevenrooms/react-components/components/CircularProgress'

export function AvailabilityCalendarLoader({ minHeight, maxHeight }: { minHeight?: number | string; maxHeight?: number | string }) {
  return (
    <Box display="flex" minHeight={minHeight} maxHeight={maxHeight} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  )
}
