// Consider using Typescript
/* eslint-disable react/prop-types */
import { formatDistance } from 'date-fns/formatDistance'
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useNavState } from 'mgr/lib/actions/NavActions'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { VenueGroupNavSections, VenueGroupSubNavSections } from 'mgr/pages/shared/utils/Constants'
import {
  tryLoadVenuePods,
  resetUserState,
  onTextFieldChange,
  onGroupPermissionChange,
  onPrimaryAccessChange,
  onAccessRoleEntitiesChange,
  addAdditionalAccess,
  onAdtlAccessChange,
  onAdtlAccessRoleEntitiesChange,
  onDeletePrimaryAccess,
  onDeleteAccess,
  onUserOptionChange,
  invalidateUserFields,
  tryLoadUser,
  tryCreateSaveUser,
  tryDeleteUser,
  tryLockUser,
  toggleDeleteWarning,
  displayAccessDefinitionModal,
  closeAccessDefinitionModal,
} from 'mgr/pages/venue-group/actions/UserAccounts'
import { tryLoadVmsRoleTemplates } from 'mgr/pages/venue-group/actions/UserRoles'
import { AccessDefinitionModal } from 'mgr/pages/venue-group/components/AccessDefinitionModal'
import { VmsIcons } from 'svr/common/VmsIcons'
import ActiveLabelTextInput from 'svr/component-lib/Generic/TextInputs/ActiveLabelTextInput'
import ActionButton from 'svr/component-lib/Manager/Buttons/ActionButton'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import IconDropdownMenu from 'svr/component-lib/Manager/Menus/IconDropdownMenu'
import SearchGroupDropdownMenu from 'svr/component-lib/Manager/Menus/SearchGroupDropdownMenu'
import MessageBox from 'svr/component-lib/Manager/MessageBox'
import SectionDropdown from 'svr/component-lib/Manager/Section'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { CheckboxGroup, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text, Anchor } from '@sevenrooms/core/ui-kit/typography'

const LastSectionWrapper = styled.div`
  padding-bottom: 48px;
`
const SectionContainer = styled.div`
  width: 715px;
  padding: ${props => props.theme.padding.large} 0 0 ${props => props.theme.padding.large};
`
const SectionWrapper = styled.div``
const SectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.customStyles}
`
const UserLockedBanner = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  background-color: #f3376c;
  padding: ${props => props.theme.padding.small} 0;
`
const UserLockedText = styled.div`
  padding-right: 6px;
`
const UserUnlockHref = styled.a`
  color: #fff !important;
  text-decoration: underline;
  cursor: pointer;
`
const TextInputWrapper = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0 ${props => props.theme.padding.small};
`
const AdtlAccessRow = styled.div`
  padding-top: 24px;
`
const AccessRow = styled.div``
const HeaderLabelContainer = styled.div``
const HeaderLabel = styled.div`
  font-size: ${props => props.theme.fontSize.body};
  font-weight: 500;
`
const SubHeaderLabel = styled.div`
  font-size: ${props => props.theme.fontSize.small};
  padding-top: ${props => props.theme.padding.xSmall};
  color: #6a6a6a;
`
const AccessDropdownsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
`
const AccessEntitiesContainer = styled.div``
const AccessEntitiesDropdownsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
`
const AtContainer = styled.span`
  font-size: 15px;
`
const IconDropdownContainer = styled.div``
const PermissionsContainer = styled.div``
const padRowStyles = css`
  padding-top: ${props => props.theme.padding.medium};
`
const customButtonStyles = css`
  border: 1px solid #347baf;
  color: #347baf;
`

const DeleteWarning = ({ proceed, cancel }) => (
  <MessageBox
    handleActionClick={proceed}
    handleCloseClick={cancel}
    dialogType={MessageBox.DialogType.WARNING}
    header="ARE YOU SURE"
    actionButtonText="Confirm"
    explanation="Are you sure you want to remove this user? This will remove access from all venues. This cannot be undone."
  />
)

export default function CreateEditUserLayout({ match, history }) {
  useNavState(VenueGroupNavSections.USERS, VenueGroupSubNavSections.ROLES)
  const [historyPath] = useState(history.location.pathname)
  const venueGroup = useSelector(state => state.appState.venueGroup)
  const dispatch = useDispatch()

  const pathName = match.path
  const pathParts = pathName.split('/')
  const urlPath = pathName.substr(0, pathName.lastIndexOf('/'))
  const isEdit = pathParts[pathParts.length - 1] === 'edit'

  useEffect(() => {
    if (isEdit) {
      const historyParts = historyPath.split('/')
      const userId = historyParts[historyParts.length - 1]
      dispatch(tryLoadUser(userId))
    } else {
      dispatch(resetUserState())
    }
  }, [dispatch, isEdit, historyPath])

  useEffect(() => {
    dispatch(tryLoadVmsRoleTemplates(venueGroup))
    dispatch(tryLoadVenuePods(venueGroup))
  }, [dispatch, venueGroup])

  const successHandlerCallback = useCallback(() => {
    history.push(urlPath)
  }, [urlPath, history])

  return <CreateEditUser isEdit={isEdit} urlPath={urlPath} successHandlerCallback={successHandlerCallback} />
}

function CreateEditUser({ isEdit, urlPath, successHandlerCallback }) {
  const {
    currentUser,
    roleId,
    selectedUserFirstName,
    selectedUserLastName,
    selectedUserEmail,
    selectedUserId,
    selectedUserIsLocked,
    showAccessDefinitionModal,
    showWarning,
    userDomain,
    venueGroup,
    vmsRoleTemplates,
  } = useSelector(state => ({
    currentUser: state.appState.user,
    roleId: state.users.roleId,
    selectedUserFirstName: state.users.selectedUserFirstName,
    selectedUserLastName: state.users.selectedUserLastName,
    selectedUserEmail: state.users.selectedUserEmail,
    selectedUserId: state.users.selectedUserId,
    selectedUserIsLocked: state.users.selectedUserIsLocked,
    showAccessDefinitionModal: state.users.showAccessDefinitionModal,
    showWarning: state.users.showWarning,
    userDomain: state.appState.userDomain,
    venueGroup: state.appState.venueGroup,
    vmsRoleTemplates: state.roles.vmsRoleTemplates,
  }))
  const dispatch = useDispatch()

  const hasValidFields = useCallback(() => {
    let firstNameValid = true
    let lastNameValid = true
    const emailValid = selectedUserEmail != null && selectedUserEmail !== '' && validateEmails(selectedUserEmail)
    if (selectedUserFirstName.trim() === '') {
      firstNameValid = false
    }
    if (selectedUserLastName.trim() === '') {
      lastNameValid = false
    }
    const hasInvalidFields = !firstNameValid || !lastNameValid || !emailValid
    if (hasInvalidFields) {
      dispatch(invalidateUserFields({ firstNameValid, lastNameValid, emailValid }))
    }
    return !hasInvalidFields
  }, [dispatch, selectedUserFirstName, selectedUserLastName, selectedUserEmail])

  const handleCreateSaveUser = useCallback(async () => {
    if (hasValidFields()) {
      try {
        await dispatch(tryCreateSaveUser())
        successHandlerCallback()
      } catch (e) {
        if (e.toString().startsWith('Email provided is not valid')) {
          dispatch(invalidateUserFields({ emailValid: false }))
        } else {
          throw e
        }
      }
    }
  }, [dispatch, hasValidFields, successHandlerCallback])

  const setLockUser = useCallback(
    async (userId, lockUser) => {
      await dispatch(tryLockUser(userId, lockUser))
      successHandlerCallback()
    },
    [dispatch, successHandlerCallback]
  )

  const handleDeleteUser = useCallback(
    async userId => {
      dispatch(toggleDeleteWarning(false))
      await dispatch(tryDeleteUser(userId))
      successHandlerCallback()
    },
    [dispatch, successHandlerCallback]
  )

  const primaryButtonText = isEdit ? 'Save' : 'Create'
  const isDisabled = !userDomain.f_user_admin || (currentUser.id === selectedUserId && !currentUser.is_superhero)
  const backgroundColor = isDisabled ? '#CCC' : '#347baf'
  return (
    <ContentLayout
      title={isEdit ? 'Edit User Account' : 'Create User Account'}
      routes={[
        {
          name: 'User Accounts',
          route: `${urlPath}`,
        },
        {
          name: `${isEdit ? 'Edit' : 'Create'}`,
        },
      ]}
      venueGroup={venueGroup}
      userDomain={userDomain}
      Actions={
        <ActionButton
          fontSize="15px"
          horizontalPadding="46px"
          onClickHandler={isDisabled ? undefined : handleCreateSaveUser}
          dataTest={`${primaryButtonText}-user-button`}
          className={`${primaryButtonText}-user-button`}
          actionText={`${primaryButtonText}`}
          backgroundColor={backgroundColor}
          disabled={isDisabled}
        />
      }
      AdditionalActions={<EditUserDropdown isEdit={isEdit} setLockUser={setLockUser} />}
    >
      {selectedUserIsLocked && (
        <UserLockedBanner>
          <UserLockedText>
            This account is locked. The user of this account must reset their password to log in and access any venues.
          </UserLockedText>
          <UserUnlockHref onClick={() => setLockUser(selectedUserId, false)}>Unlock Account.</UserUnlockHref>
        </UserLockedBanner>
      )}
      {isDisabled && (
        <UserLockedBanner>
          <UserLockedText>You cannot edit your own user account.</UserLockedText>
        </UserLockedBanner>
      )}
      <UserInfoSection isEdit={isEdit} onPasswordReset={() => setLockUser(selectedUserId, true)} />
      <PermissionsContainer>
        <VenueAccessPermissionsSection />
        <GroupPortalPermissionsSection />
      </PermissionsContainer>
      <Window active={showAccessDefinitionModal && vmsRoleTemplates.length > 0}>
        <AccessDefinitionModal roleId={roleId} vmsRoleTemplates={vmsRoleTemplates} cancel={() => dispatch(closeAccessDefinitionModal())} />
      </Window>
      {showWarning && selectedUserId && (
        <DeleteWarning proceed={() => handleDeleteUser(selectedUserId)} cancel={() => dispatch(toggleDeleteWarning(false))} />
      )}
    </ContentLayout>
  )
}

function UserInfoSection({ isEdit, onPasswordReset }) {
  const { selectedUserFirstName, selectedUserLastName, selectedUserEmail, firstNameValid, lastNameValid, emailValid } = useSelector(
    state => ({
      selectedUserFirstName: state.users.selectedUserFirstName,
      selectedUserLastName: state.users.selectedUserLastName,
      selectedUserEmail: state.users.selectedUserEmail,
      firstNameValid: state.users.firstNameValid,
      lastNameValid: state.users.lastNameValid,
      emailValid: state.users.emailValid,
    })
  )

  const dispatch = useDispatch()
  const handleTextChange = useCallback((field, val) => dispatch(onTextFieldChange(field, val)), [dispatch])

  return (
    <SectionContainer>
      <SectionDropdown headerId="user-information" headerText="Information" showCaret={false} isOpenDefault>
        <SectionWrapper>
          <SectionRow>
            <TextInputWrapper>
              <ActiveLabelTextInput
                field="firstName"
                dataTest="first-name"
                onChange={val => handleTextChange('selectedUserFirstName', val)}
                value={selectedUserFirstName}
                placeholderLabel="First Name*"
                isValid={firstNameValid}
              />
            </TextInputWrapper>
            <TextInputWrapper>
              <ActiveLabelTextInput
                field="lastName"
                dataTest="last-name"
                onChange={val => handleTextChange('selectedUserLastName', val)}
                value={selectedUserLastName}
                placeholderLabel="Last Name*"
                isValid={lastNameValid}
              />
            </TextInputWrapper>
          </SectionRow>
          <SectionRow customStyles={padRowStyles}>
            <TextInputWrapper>
              <ActiveLabelTextInput
                field="email"
                dataTest="email"
                onChange={val => handleTextChange('selectedUserEmail', val)}
                value={selectedUserEmail}
                placeholderLabel="Email*"
                isValid={emailValid}
                disabled={isEdit}
              />
            </TextInputWrapper>
          </SectionRow>
          <UserOptionsSection isEdit={isEdit} onPasswordReset={onPasswordReset} />
        </SectionWrapper>
      </SectionDropdown>
    </SectionContainer>
  )
}

function UserOptionsSection({ isEdit, onPasswordReset }) {
  const { selectedUser, selectedUserIsLocked } = useSelector(state => ({
    selectedUser: state.users.selectedUser,
    selectedUserIsLocked: state.users.selectedUserIsLocked,
  }))
  const dispatch = useDispatch()
  const updateOption = useCallback(val => dispatch(onUserOptionChange(val)), [dispatch])
  return selectedUser ? (
    <Box pl="s" pt="m">
      <VStack spacing="s">
        <Checkbox
          data-test="enable-email-alerts-checkbox"
          checked={selectedUser.emailAlertsEnabled}
          onChange={e => updateOption({ emailAlertsEnabled: e.currentTarget.checked })}
        >
          Enable Email Alerts
        </Checkbox>
        {!selectedUser.isSSOUser && (
          <Checkbox
            data-test="enable-mfa-checkbox"
            checked={selectedUser.isMfaEnabled}
            onChange={e => updateOption({ isMfaEnabled: e.currentTarget.checked })}
          >
            Multifactor authentication enabled.
          </Checkbox>
        )}
        <Checkbox
          data-test="suspend-user-checkbox"
          checked={selectedUser.isSuspended}
          onChange={e => updateOption({ isSuspended: e.currentTarget.checked })}
        >
          Suspend Access Temporarily
        </Checkbox>
        {isEdit && (
          <>
            <HStack spacing="xs">
              <Text fontWeight="bold">Last Login</Text>
              <TimeSinceDisplay data-test="last-login" datetime={selectedUser.lastLogin} />
            </HStack>
            <HStack spacing="xs">
              <Text fontWeight="bold">Last Password Reset</Text>
              {selectedUserIsLocked ? (
                <Text data-test="last-password-reset">Pending Reset</Text>
              ) : (
                <>
                  <TimeSinceDisplay data-test="last-password-reset" datetime={selectedUser.lastPwReset} />
                  <Anchor data-test="password-reset-link" onClick={onPasswordReset}>
                    Reset Password/Logout Now
                  </Anchor>
                </>
              )}
            </HStack>
          </>
        )}
      </VStack>
    </Box>
  ) : null
}

function TimeSinceDisplay({ datetime, 'data-test': dataTest }) {
  const formatted = useMemo(
    () =>
      datetime
        ? {
            date: DateOnly.fromDate(datetime).formatNYearNMonthNDay(),
            since: formatDistance(datetime, new Date(), { addSuffix: true }),
          }
        : null,
    [datetime]
  )

  return formatted ? (
    <Text data-test={dataTest}>
      {formatted.date} ({formatted.since})
    </Text>
  ) : (
    <Text data-test={dataTest}>N/A</Text>
  )
}

function VenueAccessPermissionsSection() {
  const {
    formattedVenuePodsChoices,
    formattedVmsRoleTemplates,
    selectedUserPrimaryAccessRoleId,
    selectedUserPrimaryAccessEntities,
    selectedUserAdtlAccesses,
    vmsRoleTemplates,
  } = useSelector(state => ({
    formattedVenuePodsChoices: state.users.formattedVenuePodsChoices,
    formattedVmsRoleTemplates: state.roles.formattedVmsRoleTemplates,
    selectedUserPrimaryAccessRoleId: state.users.selectedUserPrimaryAccessRoleId,
    selectedUserPrimaryAccessEntities: state.users.selectedUserPrimaryAccessEntities,
    selectedUserAdtlAccesses: state.users.selectedUserAdtlAccesses,
    showAccessDefinitionModal: state.users.showAccessDefinitionModal,
    vmsRoleTemplates: state.roles.vmsRoleTemplates,
  }))

  const dispatch = useDispatch()

  const handlePrimaryAccessChange = useCallback(val => dispatch(onPrimaryAccessChange(val)), [dispatch])
  const handleAccessRoleEntitiesChange = useCallback(
    roleId => dispatch(onAccessRoleEntitiesChange(selectedUserPrimaryAccessRoleId, roleId)),
    [dispatch, selectedUserPrimaryAccessRoleId]
  )
  const handleDeletePrimaryAccess = useCallback(() => dispatch(onDeletePrimaryAccess()), [dispatch])
  const addNewAccess = useCallback(() => dispatch(addAdditionalAccess()), [dispatch])

  return (
    <SectionContainer>
      <SectionDropdown headerId="venue-access-permissions" headerText="Venue Access Permissions" showCaret={false} isOpenDefault>
        <SectionWrapper>
          <AccessRow>
            <HeaderLabelContainer>
              <HeaderLabel>Primary Access</HeaderLabel>
              <SubHeaderLabel>This should be the access most aligned with your primary job function.</SubHeaderLabel>
            </HeaderLabelContainer>
            <AccessDropdownsContainer>
              <DropdownArrowsPicker
                name="primary-access"
                noHeader
                isLightTheme
                onChangeHandler={handlePrimaryAccessChange}
                choices={formattedVmsRoleTemplates}
                value={selectedUserPrimaryAccessRoleId}
                placeholder="Choose Role"
                height={60}
                marginRight="0px"
                style={{
                  width: '275px',
                }}
              />
              <AtContainer>at</AtContainer>
              <SearchGroupDropdownMenu
                name="primary-access-search"
                isLightTheme
                placeholder="Search Venue/Pod/Group"
                groups={formattedVenuePodsChoices}
                values={selectedUserPrimaryAccessEntities}
                onChangeHandler={handleAccessRoleEntitiesChange}
                height={60}
                style={{
                  width: '310px',
                }}
              />
              <IconDropdownContainer>
                <IconDropdownMenu
                  choices={
                    selectedUserPrimaryAccessRoleId
                      ? [
                          {
                            name: 'Delete',
                            icon: VmsIcons.Delete,
                            onClickHandler: handleDeletePrimaryAccess,
                          },
                          {
                            name: 'Access definition',
                            icon: VmsIcons.Info,
                            onClickHandler: () => dispatch(displayAccessDefinitionModal(selectedUserPrimaryAccessRoleId, vmsRoleTemplates)),
                          },
                        ]
                      : [
                          {
                            name: 'Delete',
                            icon: VmsIcons.Delete,
                            onClickHandler: handleDeletePrimaryAccess,
                          },
                        ]
                  }
                  isLightTheme
                  name="primary-access-icon"
                  id="primary-access-icon"
                  icon={VmsIcons.More}
                  textAlign="right"
                  outerDropdownPos="relative"
                  optionsWidth="200px"
                  style={{
                    minWidth: '0px',
                  }}
                />
              </IconDropdownContainer>
            </AccessDropdownsContainer>
          </AccessRow>
          <AdtlAccessRow>
            <HeaderLabelContainer>
              <HeaderLabel>Additional Access</HeaderLabel>
              <SubHeaderLabel>This is any other access to venues/pods/group.</SubHeaderLabel>
            </HeaderLabelContainer>
            {selectedUserAdtlAccesses.length > 0 && (
              <AccessEntitiesContainer>
                {selectedUserAdtlAccesses.map((entity, idx) => (
                  <AccessDropdownsContainer key={`adtl-access-container-${entity.id}`}>
                    <DropdownArrowsPicker
                      name="adtl-access"
                      noHeader
                      isLightTheme
                      onChangeHandler={val => dispatch(onAdtlAccessChange(idx, val))}
                      displayOverride={entity.roleId === 'CUSTOM' ? `Custom (${entity.roleName})` : null}
                      choices={formattedVmsRoleTemplates}
                      value={entity.roleId}
                      placeholder="Choose Role"
                      height={60}
                      marginRight="0px"
                      style={{
                        width: '275px',
                      }}
                    />
                    <AtContainer>at</AtContainer>
                    <SearchGroupDropdownMenu
                      name={`adtl-search-access-container-${idx}`}
                      isLightTheme
                      placeholder="Search Venue/Pod/Group"
                      groups={formattedVenuePodsChoices}
                      canSelectOptions={entity.roleId !== 'CUSTOM'}
                      values={entity.accessEntities}
                      onChangeHandler={val => dispatch(onAdtlAccessRoleEntitiesChange(idx, val))}
                      height={60}
                      style={{
                        width: '310px',
                      }}
                    />
                    <IconDropdownContainer>
                      <IconDropdownMenu
                        choices={
                          entity.roleId
                            ? [
                                {
                                  name: 'Delete',
                                  icon: VmsIcons.Delete,
                                  onClickHandler: () => dispatch(onDeleteAccess(idx)),
                                },
                                {
                                  name: 'Access definition',
                                  icon: VmsIcons.Info,
                                  onClickHandler: () => dispatch(displayAccessDefinitionModal(entity.roleId, vmsRoleTemplates)),
                                },
                              ]
                            : [
                                {
                                  name: 'Delete',
                                  icon: VmsIcons.Delete,
                                  onClickHandler: () => dispatch(onDeleteAccess(idx)),
                                },
                              ]
                        }
                        isLightTheme
                        name={`adtl-access-icon-${idx}`}
                        id={`adtl-access-icon-${idx}`}
                        icon={VmsIcons.More}
                        textAlign="right"
                        outerDropdownPos="relative"
                        optionsWidth="200px"
                        style={{
                          minWidth: '0px',
                        }}
                      />
                    </IconDropdownContainer>
                  </AccessDropdownsContainer>
                ))}
              </AccessEntitiesContainer>
            )}
            <AccessEntitiesDropdownsContainer>
              <ActionButton
                actionIcon={VmsIcons.Plus}
                fontSize="15px"
                horizontalPadding="6px"
                onClickHandler={addNewAccess}
                dataTest="add-additional-access-button"
                className="add-additional-access-button"
                actionText="Add Additional Access"
                backgroundColor="#FFF"
                customButtonStyles={customButtonStyles}
              />
            </AccessEntitiesDropdownsContainer>
          </AdtlAccessRow>
        </SectionWrapper>
      </SectionDropdown>
    </SectionContainer>
  )
}

function GroupPortalPermissionsSection() {
  const {
    selectedUserIsPodAdmin,
    selectedUserIsUserAdmin,
    selectedUserIsRoleAdmin,
    selectedAdministrateGlobalReservationTags,
    selectedAdministrateGlobalClientTags,
    selectedAdministratePodReservationTags,
    selectedAdministratePodClientTags,
    selectedUserIsVenueGroupUserAdmin,
    selectedUserIsVenueGroupUserPermissionAdmin,
    selectedUserHasGroupReportingAccess,
    isLookerEnabled,
    canAdminVenueGroupUser,
    canAdminVenueGroupUserPermission,
  } = useSelector(state => ({
    selectedUserIsPodAdmin: state.users.f_administrate_pods,
    selectedUserIsUserAdmin: state.users.f_user_admin,
    selectedUserIsRoleAdmin: state.users.f_role_admin,
    selectedAdministrateGlobalReservationTags: state.users.f_administrate_global_reservation_tags,
    selectedAdministrateGlobalClientTags: state.users.f_administrate_global_client_tags,
    selectedAdministratePodReservationTags: state.users.f_administrate_pod_reservation_tags,
    selectedAdministratePodClientTags: state.users.f_administrate_pod_client_tags,
    selectedUserIsVenueGroupUserAdmin: state.users.f_administrate_venue_group_user,
    selectedUserIsVenueGroupUserPermissionAdmin: state.users.f_administrate_venue_group_user_permission,
    selectedUserHasGroupReportingAccess: state.users.has_access_group_reporting,
    canAdminVenueGroupUser: state.appState.userDomain.f_administrate_venue_group_user,
    canAdminVenueGroupUserPermission: state.appState.userDomain.f_administrate_venue_group_user_permission,
    isLookerEnabled: state.appState.venueGroup.isLookerEnabled === 'True',
  }))

  const currentPermissions = useMemo(
    () => ({
      f_administrate_pods: selectedUserIsPodAdmin,
      f_user_admin: selectedUserIsUserAdmin,
      f_administrate_venue_group_user: selectedUserIsVenueGroupUserAdmin,
      f_administrate_venue_group_user_permission: selectedUserIsVenueGroupUserPermissionAdmin,
      f_role_admin: selectedUserIsRoleAdmin,
      f_administrate_global_reservation_tags: selectedAdministrateGlobalReservationTags,
      f_administrate_global_client_tags: selectedAdministrateGlobalClientTags,
      f_administrate_pod_reservation_tags: selectedAdministratePodReservationTags,
      f_administrate_pod_client_tags: selectedAdministratePodClientTags,
      has_access_group_reporting: selectedUserHasGroupReportingAccess,
    }),
    [
      selectedAdministrateGlobalClientTags,
      selectedAdministrateGlobalReservationTags,
      selectedAdministratePodClientTags,
      selectedAdministratePodReservationTags,
      selectedUserHasGroupReportingAccess,
      selectedUserIsPodAdmin,
      selectedUserIsRoleAdmin,
      selectedUserIsUserAdmin,
      selectedUserIsVenueGroupUserAdmin,
      selectedUserIsVenueGroupUserPermissionAdmin,
    ]
  )

  const dispatch = useDispatch()
  const handleToggleGroupPermission = useCallback(
    selectedFields => {
      const defaultPermissionSet = {
        f_administrate_pods: false,
        f_user_admin: false,
        f_administrate_venue_group_user: false,
        f_administrate_venue_group_user_permission: false,
        f_role_admin: false,
        f_administrate_global_client_tags: false,
        f_administrate_global_reservation_tags: false,
        f_administrate_pod_client_tags: false,
        f_administrate_pod_reservation_tags: false,
        has_access_group_reporting: false,
      }
      if (!selectedFields.includes('f_user_admin') && selectedFields.includes('f_administrate_venue_group_user_permission')) {
        selectedFields.splice(selectedFields.indexOf('f_administrate_venue_group_user_permission'), 1)
      }
      selectedFields.forEach(item => {
        defaultPermissionSet[item] = true
      })
      dispatch(onGroupPermissionChange(defaultPermissionSet))
    },
    [dispatch]
  )

  const customFieldOptions = useMemo(
    () => [
      {
        value: 'f_administrate_venue_group_user',
        label: 'Group User Administrator',
        info: 'Can view/edit users for entire venue group',
        disabled: !canAdminVenueGroupUserPermission,
        selected: selectedUserIsVenueGroupUserAdmin,
      },
      {
        value: 'f_administrate_pods',
        label: 'Pod Administrator',
        info: 'Can edit pods for entire venue group',
        disabled: !canAdminVenueGroupUserPermission,
        selected: selectedUserIsPodAdmin,
      },
      {
        value: 'f_user_admin',
        label: 'Group User Management: Venue Access Administrator',
        info: 'Can manage Venue Access Permissions for all users',
        disabled: !canAdminVenueGroupUserPermission,
        selected: selectedUserIsUserAdmin,
        canNestingCheckboxBeSelected: true,
        choices: [
          {
            value: 'f_administrate_venue_group_user_permission',
            label: 'Group User Management: Group Portal Permissions Administrator',
            info: 'Can manage Group Portal Permissions for all users',
            disabled: !canAdminVenueGroupUserPermission || !selectedUserIsUserAdmin,
            selected: selectedUserIsVenueGroupUserPermissionAdmin,
          },
        ],
      },
      {
        value: 'f_role_admin',
        label: 'Roles Administrator',
        info: 'Can edit roles for entire venue group',
        disabled: !canAdminVenueGroupUserPermission,
        selected: selectedUserIsRoleAdmin,
      },
      {
        value: 'f_administrate_global_tags',
        label: 'Global Tag Administrator',
        info: 'Can edit global tags for entire venue group',
        disabled: !canAdminVenueGroupUserPermission,
        selected: selectedAdministrateGlobalReservationTags || selectedAdministrateGlobalClientTags,
        choices: [
          {
            value: 'f_administrate_global_reservation_tags',
            label: 'Manage Global Reservation Tags',
            info: 'Can edit global reservation tags',
            disabled: !canAdminVenueGroupUserPermission,
            selected: selectedAdministrateGlobalReservationTags,
          },
          {
            value: 'f_administrate_global_client_tags',
            label: 'Manage Global Client Tags',
            info: 'Can edit global client tags',
            disabled: !canAdminVenueGroupUserPermission,
            selected: selectedAdministrateGlobalClientTags,
          },
        ],
      },
      {
        value: 'f_administrate_pod_tags',
        label: 'Pod Tag Administrator',
        info: 'Can edit pod tags for entire venue group',
        disabled: !canAdminVenueGroupUserPermission,
        selected: selectedAdministratePodReservationTags || selectedAdministratePodClientTags,
        choices: [
          {
            value: 'f_administrate_pod_reservation_tags',
            label: 'Manage Pod Reservation Tags',
            info: 'Can edit Pod reservation tags',
            disabled: !canAdminVenueGroupUserPermission,
            selected: selectedAdministratePodReservationTags,
          },
          {
            value: 'f_administrate_pod_client_tags',
            label: 'Manage Pod Client Tags',
            info: 'Can edit Pod client tags',
            disabled: !canAdminVenueGroupUserPermission,
            selected: selectedAdministratePodClientTags,
          },
        ],
      },
      {
        value: 'has_access_group_reporting',
        label: 'Group Reporting',
        info: 'Can access group reporting for entire venue group',
        disabled: !isLookerEnabled || !canAdminVenueGroupUserPermission,
        selected: selectedUserHasGroupReportingAccess,
      },
    ],
    [
      canAdminVenueGroupUserPermission,
      isLookerEnabled,
      selectedAdministrateGlobalClientTags,
      selectedAdministrateGlobalReservationTags,
      selectedAdministratePodClientTags,
      selectedAdministratePodReservationTags,
      selectedUserHasGroupReportingAccess,
      selectedUserIsPodAdmin,
      selectedUserIsRoleAdmin,
      selectedUserIsUserAdmin,
      selectedUserIsVenueGroupUserAdmin,
      selectedUserIsVenueGroupUserPermissionAdmin,
    ]
  )

  const selectedOptions = Object.entries(currentPermissions)
    .filter(v => v[1])
    .map(v => v[0])

  return (
    <LastSectionWrapper>
      <SectionContainer>
        <SectionDropdown headerId="group-portal-permissions" headerText="Group Portal Permissions" showCaret isOpenDefault={false}>
          <CheckboxGroup
            name="Global Tag Administrator"
            selected={selectedOptions}
            choices={customFieldOptions}
            onChange={handleToggleGroupPermission}
            canNestingCheckboxBeSelected
            data-test="sr-checkbox"
          />
        </SectionDropdown>
      </SectionContainer>
    </LastSectionWrapper>
  )
}

function EditUserDropdown({ isEdit, setLockUser }) {
  const { selectedUserId, selectedUserIsLocked } = useSelector(state => ({
    selectedUserId: state.users.selectedUserId,
    selectedUserIsLocked: state.users.selectedUserIsLocked,
  }))

  const dispatch = useDispatch()

  return (
    isEdit && (
      <IconDropdownContainer>
        <IconDropdownMenu
          choices={[
            {
              name: selectedUserIsLocked ? 'Unlock' : 'Lock',
              icon: VmsIcons.Lock,
              onClickHandler: () => setLockUser(selectedUserId, !selectedUserIsLocked),
            },
            {
              name: 'Delete',
              icon: VmsIcons.Delete,
              onClickHandler: () => dispatch(toggleDeleteWarning(true, selectedUserId)),
            },
          ]}
          isLightTheme
          name="edit-user-actions"
          id="edit-user-actions"
          icon={VmsIcons.More}
          textAlign="right"
          outerDropdownPos="relative"
          optionsWidth="150px"
          style={{
            minWidth: '0px',
          }}
        />
      </IconDropdownContainer>
    )
  )
}

export const validateEmail = email =>
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9-]*[a-z0-9])?$/gi.test(
    email
  )

/**
 * Validates a comma delimited list of emails
 * @param emails - A comma delimited list of emails
 * @returns Whether all emails passed validation
 */
export function validateEmails(emails) {
  return (
    emails == null ||
    emails === '' ||
    emails.split(',').every(email => {
      try {
        return validateEmail(email.trim())
      } catch (e) {
        return false
      }
    })
  )
}
