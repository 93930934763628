import _ from 'lodash'
import Radium from 'radium'
import React from 'react'
import * as navItems from 'mgr/layout/NavItemsConsts'
import { VenueGroupNavSections } from 'mgr/pages/shared/utils/Constants'
import { merge } from '../../common/SRUtils'
import { VmsIcons, VmsIconSizes } from '../../common/VmsIcons'
import Navigation from './Nav'
import { NavigationStyles, NavigationCellStyles } from './Styles'

export class VenueGroupNavigation extends Navigation {
  constructor(props) {
    super(props)
  }

  render() {
    const { venueGroup, venueGroupId, activeSection, hasVenueUsers, features } = this.props
    const { canAdminPods, canAdminGlobalTags, canAdminPodTags, canAdminUsers, canAdminRoles, hasAccessGroupReporting } = features
    const venueGroupBaseUrl = '/group'
    const getActiveStyle = section => (activeSection === section ? NavigationCellStyles.active : {})

    const { lookerFolders } = venueGroup
    const isLookerActive = venueGroup.isLookerEnabled && !_.isEmpty(lookerFolders)

    const firstReportingLink = `${venueGroupBaseUrl}/${venueGroupId}/reporting/home`

    const firstUsersLink = canAdminUsers
      ? `${venueGroupBaseUrl}/${venueGroupId}/users/accounts`
      : `${venueGroupBaseUrl}/${venueGroupId}/users/roles`

    return (
      <div style={_.extend({}, NavigationStyles.body, { paddingTop: '25px' })}>
        <a href={`${venueGroupBaseUrl}/${venueGroupId}`} style={NavigationCellStyles.link}>
          <div style={merge(VmsIconSizes.medium, NavigationStyles.logo)}>{VmsIcons.LogoSymbol}</div>
        </a>
        <div
          style={_.extend({}, NavigationStyles.scrollableNavContainer, {
            top: 85,
          })}
        >
          <div
            className="svr-nav"
            ref={scrollRef => {
              this.scrollRef = scrollRef
            }}
            style={NavigationStyles.scrollableNav}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
          >
            <div style={NavigationStyles.navButtons}>
              {this.getNavigationCell(
                canAdminPods,
                VmsIcons.SiteMapLine,
                VmsIcons.SiteMap,
                activeSection === VenueGroupNavSections.PODS,
                'Pods',
                `${venueGroupBaseUrl}/${venueGroupId}/pods`
              )}
              {this.getNavigationCell(
                canAdminGlobalTags || canAdminPodTags,
                VmsIcons.LocalOfferLine,
                VmsIcons.LocalOffer,
                activeSection === VenueGroupNavSections.TAGS,
                'Tags',
                `${venueGroupBaseUrl}/${venueGroupId}/tags/client`
              )}
              {this.getNavigationCell(
                hasAccessGroupReporting,
                VmsIcons.ReportingLine,
                VmsIcons.Reporting,
                activeSection === VenueGroupNavSections.REPORTING,
                'Reporting',
                firstReportingLink
              )}
              {this.getNavigationCell(
                canAdminUsers || canAdminRoles,
                VmsIcons.ClientsLine,
                VmsIcons.Clients,
                activeSection === VenueGroupNavSections.USERS,
                'Users',
                firstUsersLink
              )}
              {this.getPortalSwitchCell(
                hasVenueUsers,
                VmsIcons.SwapHoriz,
                null,
                false,
                'Venue',
                `/manager/switch_portal/venue?from_venue_group_id=${venueGroupId}`
              )}
            </div>
            <div style={NavigationStyles.profileButtons}>
              <a key="_help" style={merge(NavigationStyles.smallLink, getActiveStyle(navItems.HELP))} href="/help">
                Help
              </a>
              <a key="_logout" style={merge(NavigationStyles.smallLink, getActiveStyle(navItems.LOGOUT))} href="/logout">
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
VenueGroupNavigation = Radium(VenueGroupNavigation)

export default VenueGroupNavigation
