/* eslint-disable camelcase */
// snake case required for usage in GenericTagsDropDown component

export enum ImportType {
  RESERVATION = 'reservation',
  CLIENT = 'client',
}
export interface TagGroupFromBackend {
  id: string
  name: string
  color_hex: string
  tags: string[]
}

export interface NewTagGroup extends TagGroupFromBackend {
  isNew: boolean
}

export type MappedTags = Record<
  string,
  {
    tagGroup: { name_display: string; tag_name_displays: {}; id: string; name: string; color_hex: string; tags: string[] }
    tagName: string
  }
>

export interface MappedTagFromBackend {
  tag_group_id: string
  tag_name: string
}

export interface FlatFileFieldValidator {
  validate: 'regex_matches' | 'required'
  regex?: string
}

export interface FlatFileFieldMapping {
  key: string
  label: string
  validators: FlatFileFieldValidator[]
  type?: string
  options?: string[]
}

export interface ImportInitialData {
  mappedTagsFromBackend: Record<string, MappedTagFromBackend>
  fields: FlatFileFieldMapping[]
  dateFields: string[]
  tagGroups: TagGroupFromBackend[]
  licenseKey: string
  companyId: string
  companyName: string
  email: string
  name: string
  userId: string
}

export interface ReservationAndClientImportState {
  isInitialDataLoaded: boolean
  tagGroups: (TagGroupFromBackend | NewTagGroup)[]
  fields: FlatFileFieldMapping[]
  licenseKey: string | null
  mappedTags: MappedTags
  blobstoreUploadKey: string | null
  importType: string | null
  importId: string | null
  isPopUpOpen: boolean
  isSubmitting: boolean
  companyId?: string
  companyName?: string
  email?: string
  name?: string
  userId?: string
  dateFields?: string[]
}

export interface FlatFileRow {
  data: {
    $custom: Record<string, string>
    [key: string]: string | Record<string, string>
  }
}

export interface FlatFileResults {
  $data: FlatFileRow[]
}
