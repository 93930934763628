import queryString from 'query-string'
import React, { PureComponent } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router'
import styled from 'styled-components'
import * as navItems from 'mgr/layout/NavItemsConsts'
import VenueNavigation from 'mgr/layout/VenueNav'
import { selectNavigationPermissions } from 'mgr/lib/utils/PermissionsUtils'
import { ErrorNotFound } from 'mgr/pages/default'
import history from 'mgr/pages/shared/utils/History'
import DayViewLayout from 'mgr/pages/single-venue/dayview'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import ReportingLayout from 'mgr/pages/single-venue/reporting'
import SettingsLayout from 'mgr/pages/single-venue/settings'
import { Insights } from '@sevenrooms/mgr-insights/views'
import { Marketing } from '@sevenrooms/mgr-marketing'
import { Orders } from '@sevenrooms/mgr-orders/Orders'
import { Settings } from '@sevenrooms/mgr-settings/Settings'
import { AvailabilityDebugger } from '@sevenrooms/mgr-availability-debugger'

// TODO fix/remove when we can update main Nav.jsx
const getActiveSection = path => {
  let activeSection

  // TODO step through each path part (split on slashes '/') and find first part that matches one of the keywords
  if (path.includes('marketing')) {
    activeSection = navItems.MARKETING
  } else if (path.includes('grid')) {
    activeSection = navItems.GRID
  } else if (path.includes('floorplan')) {
    activeSection = navItems.FLOORPLAN
  } else if (path.includes('reporting')) {
    activeSection = navItems.REPORTS
  } else if (path.includes('settings')) {
    activeSection = navItems.SETTINGS
  } else if (path.includes('orders')) {
    activeSection = navItems.ORDERS
  } else if (path.includes('insights')) {
    activeSection = navItems.INSIGHTS
  }

  return activeSection
}

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
`

class App extends PureComponent {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    moment.locale(this.props.venueLocale)
    // eslint-disable-next-line no-console
    console.log(`Setting locale ${this.props.venueLocale}`)
  }

  // TODO get this working for all the back button functionality we want
  // just supports the shift changes right now
  componentWillReceiveProps(nextProps) {
    const { venue, actions, date } = this.props
    const nextLocationParams = queryString.parse(nextProps.location.search)

    if (
      !_.isEmpty(venue) &&
      date.isSame(nextProps.date) &&
      nextProps.shiftCategory &&
      nextLocationParams.shift &&
      nextProps.shiftCategory !== nextLocationParams.shift
    ) {
      actions.changeShift({ category: nextLocationParams.shift })
    }
  }

  render() {
    const {
      venue,
      venueSettings,
      date,
      shiftCategory,
      shifts,
      permissions,
      user,
      activeSection,
      hasVenueGroupUsers,
      match,
      ordersTabEnabled,
    } = this.props
    const venueUrlKey = venue.urlKey
    const dateFormatted = date.format('MM-DD-YYYY')
    const dayViewPath = `${match.path}/${venueUrlKey}/${dateFormatted}/reservations`

    return (
      <ContentContainer>
        <VenueNavigation
          permissions={permissions}
          user={user}
          venue={venue}
          venueUrlKey={venueUrlKey}
          venueSettings={venueSettings}
          date={date}
          shiftCategory={shiftCategory}
          shifts={shifts}
          activeSection={activeSection}
          hasVenueGroupUsers={hasVenueGroupUsers}
          isRouterMode
        />
        <ContentContainer>
          <Switch>
            <Route path={dayViewPath} component={DayViewLayout} />

            <Route
              path={`${match.path}/${venueUrlKey}/settings`}
              render={() => (
                <Settings
                  renderOldSettingsLayout={() => (
                    <SettingsLayout user={user} venue={venue} venueSettings={venueSettings} permissions={permissions} />
                  )}
                />
              )}
            />

            <Route path={`${match.path}/${venueUrlKey}/marketing`} render={() => <Marketing />} />

            <Route
              path={`${match.path}/${venueUrlKey}/reporting`}
              render={() => <ReportingLayout venue={venue} venueSettings={venueSettings} permissions={permissions} />}
            />
            {ordersTabEnabled && <Route path={`${match.path}/${venueUrlKey}/orders`} render={() => <Orders />} />}

            {venueSettings.is_ro_insight_enabled &&
              (permissions.canAccessInsightsPage || (venueSettings.superhero_ro_access && user.is_superhero)) && (
                <Route
                  path={`${match.path}/${venueUrlKey}/insights`}
                  render={() => <Insights venueSettings={venueSettings} permissions={permissions} />}
                />
              )}

            {(venueSettings.is_availability_debugger_enabled || user.is_superhero) && (
              <Route path={`${match.path}/${venueUrlKey}/availability`} render={() => <AvailabilityDebugger />} />
            )}
            <Route path="*" component={ErrorNotFound} />
          </Switch>
        </ContentContainer>
      </ContentContainer>
    )
  }
}

const mapStateToProps = state => ({
  location: history && history.location,
  permissions: selectNavigationPermissions(state),
  user: state.appState.user,
  venueUrlKey: state.appState.venue.urlKey,
  venue: state.appState.venue,
  venueSettings: state.appState.venueSettings,
  venueLocale: state.appState.venue.locale,
  date: state.dayviewState.date,
  shiftCategory: state.dayviewState.shiftCategory,
  activeSection: history && history.location && getActiveSection(history.location.pathname),
  hasVenueGroupUsers: state.appState.userDomain.hasVenueGroupUsers,
  isRouterMode: true,
  isLoading: state.loadingState.isLoading,
  shifts: state.dayviewState.shifts,
  ordersTabEnabled: state.appState.venueSettings
    ? state.appState.userDomain.can_view_orders && state.appState.venueSettings.is_orders_integration_enabled
    : false,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    changeShift: shift => dispatch(Actions.changeShiftAction(shift)),
  },
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default withRouter(App)
