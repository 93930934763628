import { defineMessages } from '@sevenrooms/core/locales'

export const reportingMessages = defineMessages({
  reportingTitle: {
    id: 'reporting.reportingTitle',
    defaultMessage: 'Reporting',
  },
  reportingDescription: {
    id: 'reporting.reportingDescription',
    defaultMessage: 'Explore and investigate live metrics about your operation',
  },
  reportingSearchPlaceholder: {
    id: 'reporting.reportingSearchPlaceholder',
    defaultMessage: 'Find a report',
  },
  reportingCategoryAdministrative: {
    id: 'reporting.reportingCategoryAdministrative',
    defaultMessage: 'Administrative',
  },
  reportingCategoryStandard: {
    id: 'reporting.reportingCategoryStandard',
    defaultMessage: 'Standard',
  },
  headerAllReports: {
    id: 'reporting.headerAllReports',
    defaultMessage: 'All Reports',
  },
  headerHighlightedReports: {
    id: 'reporting.headerAllReports',
    defaultMessage: 'Highlighted Reports',
  },
  headerSearchResults: {
    id: 'reporting.headerAllReports',
    defaultMessage: 'Search Results',
  },
  badgePopular: {
    id: 'reporting.badgePopular',
    defaultMessage: '{icon} Popular',
  },
  badgeRecentlyViewed: {
    id: 'reporting.badgeRecentlyViewed',
    defaultMessage: '{icon} Recently Viewed',
  },
  dateFilterLabel: {
    id: 'reporting.dateFilterLabel',
    defaultMessage: 'Date Filters',
  },
  revenueEarned: {
    id: 'reporting.revenueEarned',
    defaultMessage: 'Revenue Earned',
  },
  missedOpportunity: {
    id: 'reporting.missedOpportunity',
    defaultMessage: 'Opportunity',
  },
  howWeCalculateTip: {
    id: 'reporting.howWeCalculateTip',
    defaultMessage: 'How do we calculate opportunity?',
  },
  calculationTip: {
    id: 'reporting.calculationTip',
    defaultMessage: `<p><strong>How do we calculate opportunity?</strong></p>
    <p>Each opportunity is calculated using the average revenue generated by the feature for venues in your region.</p>
    <p>Opportunity left on the table is the sum of each of your opportunities.</p>
    <p>Feature revenue is calculated using spend data provided by your POS integration and online prepayments. If POS
    spend data is not available, we use your estimated average spend per cover.</p>
    <p><link>Learn More</link></p>`,
  },
  youveEarned: {
    id: 'reporting.youveEarned',
    defaultMessage: "You've earned",
  },
  fromSevenRooms: {
    id: 'reporting.fromSevenRooms',
    defaultMessage: 'from SevenRooms features',
  },
  filterThisMonth: {
    id: 'reporting.filterThisMonth',
    defaultMessage: 'This month',
  },
  filterLastMonth: {
    id: 'reporting.filterLastMonth',
    defaultMessage: 'Last month',
  },
  filter3Month: {
    id: 'reporting.filter3Month',
    defaultMessage: 'Last 3 months',
  },
  filter6Month: {
    id: 'reporting.filter6Month',
    defaultMessage: 'Last 6 months',
  },
  filterYearToDate: {
    id: 'reporting.filterYearToDate',
    defaultMessage: 'Year to date',
  },
  filterLastYear: {
    id: 'reporting.filterLastYear',
    defaultMessage: 'Last year',
  },
  filterAll: {
    id: 'reporting.filterAll',
    defaultMessage: 'All time',
  },
  titleTextThisMonth: {
    id: 'reporting.titleTextThisMonth',
    defaultMessage: 'this month',
  },
  titleTextLastMonth: {
    id: 'reporting.titleTextLastMonth',
    defaultMessage: 'last month',
  },
  titleText3Month: {
    id: 'reporting.titleText3Month',
    defaultMessage: 'the last three months',
  },
  titleText6Month: {
    id: 'reporting.titleText6Month',
    defaultMessage: 'the last six months',
  },
  titleTextYearToDate: {
    id: 'reporting.titleTextYearToDate',
    defaultMessage: 'this year',
  },
  titleTextLastYear: {
    id: 'reporting.titleTextLastYear',
    defaultMessage: 'last year',
  },
  titleTextAll: {
    id: 'reporting.titleTextAll',
    defaultMessage: 'across all time',
  },
  moneyOnTable: {
    id: 'reporting.moneyOnTable',
    defaultMessage: 'There was {amount} worth of opportunity left on the table.',
  },
  opportunityExplanation: {
    id: 'reporting.opportunityExplanation',
    defaultMessage: 'Opportunities are suggested based on their performance according to a regional benchmark.',
  },
  aboutThisFeature: {
    id: 'reporting.aboutThisFeature',
    defaultMessage: 'About',
  },
  importantMetrics: {
    id: 'reporting.importantMetrics',
    defaultMessage: 'Important Metrics',
  },
  revenueEarnedHeader: {
    id: 'reporting.revenueEarnedHeader',
    defaultMessage: 'Revenue Earned',
  },
  learnMore: {
    id: 'reporting.learnMore',
    defaultMessage: 'Learn More',
  },
  exploreReport: {
    id: 'reporting.exploreReport',
    defaultMessage: '{icon} Explore Report',
  },
  regionComparison: {
    id: 'reporting.regionComparison',
    defaultMessage: 'Venues in your region earned an additional <strong>{amount}</strong> from {feature}.',
  },
  statusOpportunityLabel: {
    id: 'reporting.statusOpportunityLabel',
    defaultMessage: 'Opportunity',
  },
  statusPerformingLabel: {
    id: 'reporting.statusPerformingLabel',
    defaultMessage: 'Performing',
  },
  statusRoomToGrowLabel: {
    id: 'reporting.statusRoomToGrowLabel',
    defaultMessage: 'Room to Grow',
  },
  tryButtonText: {
    id: 'reporting.tryButtonText',
    defaultMessage: 'Try {feature}',
  },
  requestButtonText: {
    id: 'reporting.requestButtonText',
    defaultMessage: 'Request Demo',
  },
  titleEmailMarketing: {
    id: 'reporting.titleEmailMarketing',
    defaultMessage: 'Email Marketing',
  },
  titleAutomatedEmails: {
    id: 'reporting.titleAutomatedEmails',
    defaultMessage: 'Automated Emails',
  },
  titleOfferExperiences: {
    id: 'reporting.titleOfferExperiences',
    defaultMessage: 'Experiences',
  },
  titleUpgrades: {
    id: 'reporting.titleUpgrades',
    defaultMessage: 'Upgrades',
  },
  titlePrepayments: {
    id: 'reporting.titlePrepayments',
    defaultMessage: 'Other Prepayments',
  },
  titleCancellationFees: {
    id: 'reporting.titleCancellationFees',
    defaultMessage: 'Cancellation & No-Show Fees',
  },
  titleEvents: {
    id: 'reporting.titleEvents',
    defaultMessage: 'Events',
  },
  titleMultiVenueWidgets: {
    id: 'reporting.titleMultiVenueWidgets',
    defaultMessage: 'Multi-Venue Widgets',
  },
  aboutTextEmailMarketing: {
    id: 'reporting.aboutTextEmailMarketing',
    defaultMessage: 'Boost your brand, drive repeat business, and track ROI with hospitality-focused email marketing software.',
  },
  aboutTextAutomatedEmails: {
    id: 'reporting.aboutTextAutomatedEmails',
    defaultMessage: 'Engage guests, grow sales, and measure ROI effortlessly with email marketing automation.',
  },
  aboutTextOfferExperiences: {
    id: 'reporting.aboutTextOfferExperiences',
    defaultMessage:
      'Sell unique experiences to guests using Offers that can be booked through the Reservation Widget or Offers Landing Page.',
  },
  aboutTextUpgrades: {
    id: 'reporting.aboutTextUpgrades',
    defaultMessage:
      'Increase per cover spend and delight guests with optional add-ons available to pre purchase while booking a reservation.',
  },
  aboutTextPrepayments: {
    id: 'reporting.aboutTextPrepayments',
    defaultMessage: 'Secure revenue upfront with Paylinks and deposits, reducing guesswork with predictable sales.',
  },
  aboutTextCancellationFees: {
    id: 'reporting.aboutTextCancellationFees',
    defaultMessage:
      'Reduce no-shows and cancellations with guest friendly policies. Easy to implement and completely customizable based on time, party size and seating area.',
  },
  aboutTextEvents: {
    id: 'reporting.aboutTextEvents',
    defaultMessage: 'Host, promote and sell ticketed events to your customer base. Includes unique landing page and Event Widget.',
  },
  aboutTextMultiVenueWidgets: {
    id: 'reporting.aboutTextMultiVenueWidgets',
    defaultMessage: 'Maximize bookings across multiple venues effortlessly by promoting availability at sister venues.',
  },
  columnHeaderCovers: {
    id: 'reporting.columnHeaderCovers',
    defaultMessage: 'Covers',
  },
  columnHeaderBenchmark: {
    id: 'reporting.columnHeaderBenchmark',
    defaultMessage: 'Regional Benchmark',
  },
  columnHeaderRevenue: {
    id: 'reporting.columnHeaderRevenue',
    defaultMessage: 'Revenue',
  },
  requiresPackageChange: {
    id: 'reporting.requiresPackageChange',
    defaultMessage: 'Requires package change',
  },
  requiresUpgrade: {
    id: 'reporting.requiresUpgrade',
    defaultMessage: 'Requires upgrade',
  },
  benchmark: {
    id: 'reporting.benchmark',
    defaultMessage: 'Benchmark',
  },
  noRevenue: {
    id: 'reporting.noRevenue',
    defaultMessage: 'No Revenue',
  },
  clickOnAfeature: {
    id: 'reporting.clickOnAfeature',
    defaultMessage: 'Click on a feature to explore its performance and related reports.',
  },
  revenueLoadError: {
    id: 'reporting.revenueLoadError',
    defaultMessage: 'There was an error loading your reports.',
  },
})
