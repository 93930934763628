import { useMemo } from 'react'
import { useGetAvailabilityDebuggerReasonsQuery } from '@sevenrooms/core/api'
import type { AvailabilityDebuggerReasonsPayload, AvailabilityCalendarTimeslotResponse, Venue } from '@sevenrooms/core/domain'
import type { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { CircularProgress } from '@sevenrooms/react-components/components/CircularProgress'
import { Dialog } from '@sevenrooms/react-components/components/Dialog'
import { useAvailabilityUrlParams } from '../../hooks'
import { ReasonsModalContent } from './ReasonsModalContent'
import { ReasonsModalTitle } from './ReasonsModalTitle'

export type TimeslotPartialExceptTime<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>

export interface ReasonsModalProps {
  open: boolean
  onClose: () => void
  venue: Venue
  date: DateOnly
  timeslot: TimeslotPartialExceptTime<AvailabilityCalendarTimeslotResponse, 'time'>
  covers: number
}

export function ReasonsModal({ open, onClose, venue, date, timeslot, covers }: ReasonsModalProps) {
  const [urlparams] = useAvailabilityUrlParams()
  const query: AvailabilityDebuggerReasonsPayload = useMemo(
    () => ({
      ...urlparams,
      venueId: venue.id,
      partySize: covers,
      time: timeslot.time,
      date,
    }),
    [venue, covers, timeslot.time, date, urlparams]
  )

  const skip = !timeslot.isOpen || timeslot.isPast

  const { data = { reasons: [] }, isLoading } = useGetAvailabilityDebuggerReasonsQuery(query, {
    refetchOnMountOrArgChange: true,
    // Skip this call when we render a modal for a time venue is not open (grey blocks)
    skip,
  })

  if (skip) {
    data.reasons.push({
      category: 'INACTIVE',
      reason: timeslot.isPast ? 'PAST_TIME' : 'NO_SHIFT',
      text: 'inactive',
      data: {},
    })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      fullWidth
      data-test="reasons-modal-main"
      sx={{ '& .MuiDialog-paper': { maxWidth: '700px', minWidth: '500px' }, overflow: 'none' }}
    >
      <ReasonsModalTitle onClose={onClose} date={date} time={timeslot.time as TimeOnly} covers={covers} />
      {skip || (!isLoading && data) ? (
        <ReasonsModalContent
          allReasons={data.reasons}
          venue={venue}
          date={date}
          time={timeslot.time}
          covers={covers}
          duration={urlparams.durationOverride ?? 0}
        />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3, pb: 6 }}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  )
}
