import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { PulsatingDotsBlue } from 'mgr/layout/StyledComponentUtils'
import { tryFormatUrl, doneLoadingIframe, saveActivityLog } from 'mgr/pages/shared/actions/reporting/LookerReporting'

const IframeContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`

const Iframe = styled.iframe`
  flex: 1;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  border: none;

  ${props => props.ready && 'opacity: 100;'};
`

const Loader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  ${props => props.hide && 'display: none;'};
`

class LookerReportingEmbed extends Component {
  componentDidMount() {
    const { tryFormatUrl, url, isGroup, lookerFilters } = this.props
    tryFormatUrl(url, isGroup, lookerFilters)

    window.addEventListener('message', this._lookerEventHandler)
  }

  componentDidUpdate(prevProps) {
    const { url, tryFormatUrl, isGroup, lookerFilters } = this.props
    if (url !== prevProps.url) {
      tryFormatUrl(url, isGroup, lookerFilters)
    }
  }

  _lookerEventHandler = event => {
    // Looker provides different object for each event_type. We map event_type to the key we need, so we can use later.
    const supportedEvents = {
      'look:run:complete': 'look',
      'dashboard:run:complete': 'dashboard',
      'dashboard:download': 'dashboard',
      'dashboard:tile:download': 'tile',
    }

    const lookerElement = document.getElementById('id_looker_iframe')
    if (lookerElement) {
      if (event.source === lookerElement.contentWindow) {
        const eventData = JSON.parse(event.data)
        const lookerAttr = supportedEvents[eventData.type]
        if (lookerAttr && eventData) {
          this.props.saveActivityLog(lookerAttr, eventData, this.props.url)
        }
      }
    }
  }

  render() {
    const { formattedUrl, isLoading, doneLoadingIframe, iFrameLoading, url, isGroup } = this.props
    return (
      <IframeContainer>
        <Loader hide={!isLoading && !iFrameLoading}>
          <PulsatingDotsBlue />
        </Loader>
        {!isLoading && formattedUrl && (
          <Iframe
            ready={!iFrameLoading}
            onLoad={() => doneLoadingIframe(url, isGroup)}
            src={formattedUrl}
            onError={() => doneLoadingIframe(url, isGroup)}
            id="id_looker_iframe"
          />
        )}
      </IframeContainer>
    )
  }
}

const mapStateToProps = state => ({
  formattedUrl: state.looker.formattedUrl,
  iFrameLoading: state.looker.iFrameLoading,
  isLoading: state.looker.isLoading,
})

const mapDispatchToProps = {
  tryFormatUrl,
  doneLoadingIframe,
  saveActivityLog,
}

export default connect(mapStateToProps, mapDispatchToProps)(LookerReportingEmbed)
