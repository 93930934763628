import { ConnectedRouter } from 'connected-react-router'
import moment from 'moment-timezone'
import { StyleRoot } from 'radium'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import styled, { ThemeProvider } from 'styled-components'
import { ManagerStyles } from 'mgr/layout/Styles'
import Theme from 'mgr/layout/Theme'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { ErrorNotFound } from 'mgr/pages/default'
import history from 'mgr/pages/shared/utils/History'
import SingleVenueLayout from 'mgr/pages/single-venue'
import StatusMessage from 'mgr/pages/single-venue/dayview/containers/StatusMessage'
import VenueGroupLayout from 'mgr/pages/venue-group'
import LoaderBar from 'svr/common/LoaderBar'
import { AppRoot } from '@sevenrooms/mgr-core'

class GlobalLoaderBar extends LoaderBar {
  constructor(props) {
    super(props)
  }
}

const AppContainer = styled.div`
  background-color: #e6e8eb;
  font: 12px Roboto;
  webkitfontsmoothing: antialiased;
  mozosxfontsmoothing: grayscale;
  height: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  margin-left: ${ManagerStyles.NavigationWidth}px;
`

class NonReactRedirect extends PureComponent {
  render() {
    window.location.reload()
    return null
  }
}

class App extends PureComponent {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    moment.locale(this.props.globalInit.venueLocale)
    // eslint-disable-next-line no-console
    console.log(`Setting locale ${this.props.globalInit.venueLocale}`)
    this.props.actions.initialize(this.props.globalInit)
  }

  render() {
    const { isLoading } = this.props

    return (
      <AppRoot>
        <ThemeProvider theme={Theme}>
          <StyleRoot>
            <AppContainer>
              <GlobalLoaderBar isLoading={isLoading} />
              <StatusMessage />
              <ConnectedRouter history={history}>
                <main>
                  <ContentContainer>
                    <Switch>
                      {/* This part is not needed when everything is moved under /manager */}
                      <Route path="/manager" component={NonReactRedirect} />
                      <Route path="/manager2" component={SingleVenueLayout} />
                      <Route path="/group" component={VenueGroupLayout} />
                      <Route path="*" component={ErrorNotFound} />
                    </Switch>
                  </ContentContainer>
                </main>
              </ConnectedRouter>
            </AppContainer>
          </StyleRoot>
        </ThemeProvider>
      </AppRoot>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.loadingState.isLoading,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    initialize: globalInit => {
      dispatch(GlobalActions.initializeAction(globalInit))
    },
  },
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
