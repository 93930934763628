import type { RevenueSource } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, VStack, Clickable } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText, Header, Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { StatusTypeEnum, type DateFilterEnum } from '../../constants'
import { COLS } from '../../constants/utils'
import { usePerformanceTableContext } from '../../hooks'
import { reportingMessages } from '../../locales'
import { getRevenueTypeInfo, getMetricValues, formatBold, formatCurrency } from '../../utils'
import { RowChart } from '../RowChart'
import { StatusBadge } from '../Status'

interface RevenuePerformanceRowProps {
  source: RevenueSource
  filter: DateFilterEnum | undefined
}

export function RevenuePerformanceRow({ source, filter }: RevenuePerformanceRowProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const { expanded, setExpanded } = usePerformanceTableContext()
  const { title, aboutText, image, outPackageLink, learnLink, exploreLink, metricTitle1, metricTitle2, locked } = getRevenueTypeInfo(
    source?.type,
    venue,
    venueSettings
  )

  const isOpportunity = source?.status === StatusTypeEnum.OPPORTUNITY
  const showDetail = expanded === source?.type
  const formattedTitle = title ? formatMessage(title) : ''
  const [metricValue1, metricValue2] = getMetricValues(source)

  const learnDataTest = `performance-row-learn-${source.type}`
  const exploreDataTest = `performance-row-explore-${source.type}`
  const expandDataTest = `performance-row-expand-${source.type}`

  return (
    <Box data-test="performance-row" borderRadius="s" borderColor="dividerLines" backgroundColor="primaryBackground">
      <Clickable data-test={expandDataTest} onClick={() => setExpanded(showDetail ? null : source?.type)}>
        <HStack p="m" alignItems="center">
          <Box width={COLS[0]}>
            <Header type="h3">
              {locked && (
                <Box display="inline" mr="xs">
                  <Icon name="VMSWeb-lock" color="secondaryFont" />
                </Box>
              )}
              {formattedTitle}
            </Header>
          </Box>
          <Box pr="m" textAlign="right" width={COLS[1]}>
            <Text>{source?.covers}</Text>
          </Box>
          <Box pr="m" textAlign="right" width={COLS[2]}>
            <Text>{formatCurrency(venue, source ? source.benchmark : 0)}</Text>
          </Box>
          <Box pr="m" textAlign="right" width={COLS[3]}>
            <Text fontWeight="bold">{source ? formatCurrency(venue, source.revenue) : formatMessage(reportingMessages.noRevenue)}</Text>
          </Box>
          <Box pr="m" pl="m" width="138px">
            <StatusBadge statusType={source?.status} />
          </Box>
          <Box>{showDetail ? <Icon name="VMSWeb-chevron-up" size="2x" /> : <Icon name="VMSWeb-chevron-down" size="2x" />}</Box>
        </HStack>
      </Clickable>
      {showDetail && (
        <Box data-test="performance-row-detail" borderTopColor="dividerLines">
          <HStack>
            <VStack width={metricTitle1 || isOpportunity ? '320px' : '570px'} p="m">
              <Text fontSize="s" fontWeight="bold">
                {formatMessage(reportingMessages.aboutThisFeature)}
              </Text>
              <VStack flex="1" mt="s">
                <Box mb="auto">
                  <SecondaryText fontSize="s" data-test="opportunity-description-row" type="p">
                    {aboutText ? formatMessage(aboutText) : ''}
                  </SecondaryText>
                </Box>
                <Box>
                  <Text>
                    <Link data-test={learnDataTest} to={learnLink || ''} isExternal>
                      {formatMessage(reportingMessages.learnMore)}
                    </Link>
                  </Text>
                </Box>
              </VStack>
            </VStack>
            {isOpportunity && (
              <Box pt="m" pb="m" pl="l" pr="l" borderRightColor="dividerLines">
                <img alt="" width="136px" height="136px" src={image} />
              </Box>
            )}
            {metricTitle1 && !isOpportunity && (
              <VStack borderLeftColor="dividerLines" borderRightColor="dividerLines" width="250px" p="m">
                <Text fontSize="s" fontWeight="bold">
                  {formatMessage(reportingMessages.importantMetrics)}
                </Text>
                <VStack flex="1" mt="s">
                  <HStack mb="auto">
                    <Box mr="m" width="50%">
                      <SecondaryText fontSize="s">{metricTitle1}</SecondaryText>
                      <Text fontSize="s" type="p">
                        {metricValue1}
                      </Text>
                    </Box>
                    <Box>
                      <SecondaryText fontSize="s">{metricTitle2}</SecondaryText>
                      <Text fontSize="s" type="p">
                        {metricValue2}
                      </Text>
                    </Box>
                  </HStack>
                  <Box>
                    <Text>
                      <Link data-test={exploreDataTest} to={exploreLink || ''} isExternal>
                        {formatMessage(reportingMessages.exploreReport, {
                          icon: <Icon name="VMSWeb-bar-graph" />,
                        })}
                      </Link>
                    </Text>
                  </Box>
                </VStack>
              </VStack>
            )}
            {isOpportunity ? (
              <Box p="m">
                <Text fontSize="s" fontWeight="bold" type="p">
                  {formatMessage(reportingMessages.benchmark)}
                </Text>
                <Text type="p">
                  {formatMessage(reportingMessages.regionComparison, {
                    strong: (chunks: string[]) => formatBold(chunks),
                    amount: source ? formatCurrency(venue, source.benchmark - source.revenue) : 0,
                    feature: formattedTitle,
                  })}
                </Text>
                <Button data-test="opportunity-card-action-button" isExternal href={outPackageLink || ''} variant="primary" size="s">
                  {formatMessage(reportingMessages.tryButtonText, { feature: formattedTitle })}
                </Button>
                {locked && (
                  <>
                    <Box display="inline" mr="xs" ml="xs">
                      <Icon name="VMSWeb-lock" color="secondaryFont" />
                    </Box>
                    <SecondaryText fontSize="s">{formatMessage(reportingMessages.requiresPackageChange)}</SecondaryText>
                  </>
                )}
              </Box>
            ) : (
              <Box>
                <Box pt="m" pl="m">
                  <Text fontSize="s" fontWeight="bold">
                    {formatMessage(reportingMessages.revenueEarnedHeader)}
                  </Text>
                </Box>
                <Box pl="sm" pt="s" pb="sm">
                  <RowChart filter={filter} chartData={source ? source.revenueHistorical : []} />
                </Box>
              </Box>
            )}
          </HStack>
        </Box>
      )}
    </Box>
  )
}
