import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import styled from 'styled-components'
import { ErrorNotFound } from 'mgr/pages/default'
import SubNav from 'mgr/pages/shared/SubNav'
import UserAccountsLayout from 'mgr/pages/venue-group/containers/users/Accounts'
import CreateEditRoleLayout from 'mgr/pages/venue-group/containers/users/CreateEditRole'
import CreateEditUserLayout from 'mgr/pages/venue-group/containers/users/CreateEditUser'
import UserRolesLayout from 'mgr/pages/venue-group/containers/users/Roles'
import type { VenueGroup } from '@sevenrooms/mgr-core'
import type { State } from 'mgr/pages/reducers/CombineReducer'

const UsersContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: 100vh;
  overflow-y: scroll;
`

export interface UsersLayoutProps {
  venueGroup: VenueGroup
  canAdminRoles: boolean
  canAdminUsers: boolean
}

export interface subNavItemProps {
  name: string
  isTitle?: boolean
  id?: string
  link?: string
}

function DefaultRedirect({ subNavItems }: { subNavItems: subNavItemProps[] }) {
  if (subNavItems[1] && subNavItems[1].link) {
    return (
      <Route path="*">
        <Redirect to={subNavItems[1].link} />
      </Route>
    )
  }
  return <Route path="*" component={ErrorNotFound} />
}

export function UsersLayout({ venueGroup: { id }, canAdminRoles, canAdminUsers }: UsersLayoutProps) {
  const subNavItems: subNavItemProps[] = [
    {
      name: 'User Account Management',
      isTitle: true,
    },
  ]
  if (canAdminUsers) {
    subNavItems.push({
      link: `/group/${id}/users/accounts`,
      name: 'User Accounts',
      id: 'user-accounts',
    })
  }
  if (canAdminRoles) {
    subNavItems.push({
      link: `/group/${id}/users/roles`,
      name: 'Roles',
      id: 'user-roles',
    })
  }

  return (
    <UsersContainer>
      <SubNav items={subNavItems} />
      <ContentContainer>
        <Switch>
          <Route path={`/group/${id}/users/accounts/create`} component={canAdminUsers ? CreateEditUserLayout : ErrorNotFound} />
          <Route path={`/group/${id}/users/accounts/edit`} component={canAdminUsers ? CreateEditUserLayout : ErrorNotFound} />
          <Route path={`/group/${id}/users/accounts`} component={canAdminUsers ? UserAccountsLayout : ErrorNotFound} />
          <Route path={`/group/${id}/users/roles/create`} component={canAdminRoles ? CreateEditRoleLayout : ErrorNotFound} />
          <Route path={`/group/${id}/users/roles/edit`} component={canAdminRoles ? CreateEditRoleLayout : ErrorNotFound} />
          <Route path={`/group/${id}/users/roles`} component={canAdminRoles ? UserRolesLayout : ErrorNotFound} />
          <DefaultRedirect subNavItems={subNavItems} />
        </Switch>
      </ContentContainer>
    </UsersContainer>
  )
}

const mapStateToProps = (state: State) => ({
  venueGroup: state.appState.venueGroup,
  canAdminUsers: state.appState.userDomain.f_user_admin,
  canAdminRoles: state.appState.userDomain.f_role_admin,
})

export default connect(mapStateToProps)(UsersLayout)
