import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { fetchFormatUrl, postSaveActivityLog, resetVenueUserApi } from 'mgr/lib/services/LookerServices'
import {
  FORMAT_URL_START,
  FORMAT_URL_SUCCESS,
  FORMAT_URL_FAIL,
  DONE_LOADING_IFRAME,
  SAVE_ACTIVITY_LOG_START,
  SAVE_ACTIVITY_LOG_SUCCESS,
  SAVE_ACTIVITY_LOG_FAIL,
} from 'mgr/pages/shared/actions/reporting/ActionTypes'
import { getLookerItems } from 'mgr/pages/shared/utils/Reporting'

export const tryFormatUrl = (url, isGroup, lookerFilters) => (dispatch, getState) => {
  const state = getState()
  dispatch({ type: FORMAT_URL_START })
  return fetchFormatUrl(isGroup ? state.appState.venueGroup.id : state.appState.venue.id, url, isGroup, lookerFilters)
    .then(response => dispatch({ type: FORMAT_URL_SUCCESS, data: response.data }))
    .catch(err => {
      if (err.response.status === 403) {
        window.location = `/manager/${state.appState.venue.urlKey}/reports/summary`
        return
      }

      // eslint-disable-next-line no-console
      console.error(err)

      dispatch(GlobalActions.showErrorMessage('Unable to load looker report, please try again later or contact customer support.'))

      dispatch({ type: FORMAT_URL_FAIL })
    })
}

export const doneLoadingIframe = (url, isGroup) => (dispatch, getState) => {
  dispatch({ type: DONE_LOADING_IFRAME })
  const state = getState()

  if (!isGroup) {
    resetVenueUserApi(state.appState.venue.id, url)
  }
}

export const saveActivityLog = (lookerAttr, eventData, path) => (dispatch, getState) => {
  const state = getState()
  dispatch({ type: SAVE_ACTIVITY_LOG_START, reportPath: path })

  // eventData.type format is type:action:status. Eg: look:run:complete, dashboard:download
  const lookerObj = eventData[lookerAttr]

  if (lookerObj) {
    const reportFormat = eventData.fileFormat || ''
    const { venue, venueGroup } = state.appState

    let reportTitle
    if (lookerObj.title) {
      reportTitle = lookerObj.title

      if (lookerAttr === 'tile') {
        // For tiles, we prepend dashboard title to make it easier to identify the report in activity log
        const dashboardTitle = eventData.dashboard?.title
        if (dashboardTitle) {
          reportTitle = `${dashboardTitle}: ${reportTitle}`
        }
      }
    } else if (lookerObj.url) {
      // Looker doesn't send title for 'look' report. We use report url to get the title from existing LookerItems
      // We reduce items to dict to make it easier it lookup. Eg: {'/embed/looks/1': 'Test Report'}
      const lookerFolder = Object.keys(venue).length !== 0 ? venue.lookerFolders : venueGroup.lookerFolders
      const lookerItems = getLookerItems(lookerFolder, '')
      const lookerLinkNameMap = lookerItems
        .filter(item => item.link != null)
        .reduce(
          (acc, obj) => ({
            ...acc,
            [obj.link]: obj.name,
          }),
          {}
        )

      const lookerRawUrl = lookerObj.url.split('?')[0]
      reportTitle = lookerLinkNameMap[lookerRawUrl]
    }

    const venueId = venue ? venue.id : null
    const venueGroupId = venueGroup ? venueGroup.id : null

    postSaveActivityLog(venueId, venueGroupId, eventData.type, reportTitle, path, reportFormat)
      .then(_ => dispatch({ type: SAVE_ACTIVITY_LOG_SUCCESS }))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err)
        dispatch({ type: SAVE_ACTIVITY_LOG_FAIL })
      })
  } else {
    // eslint-disable-next-line no-console
    console.warn(`Looker object not found with type: ${eventData.type}.`)
    dispatch({ type: SAVE_ACTIVITY_LOG_FAIL })
  }
}
